import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import "..//HomeHero.css";
import "../components/HomeHeroUnpaid.css";
import { useNavigate } from "react-router-dom";
const HomeHeroUnPaid = () => {
  const navigate = useNavigate();
  let data = localStorage.getItem("UserInfo");
  let userData = JSON.parse(data);
  // console.log("user info", userData.name);
  console.log("user info", data);
  const [collapsed, setCollapsed] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="hero__wrapper__unpaid blur-wrapper" style={{zIndex:"0"}}>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <nav
              className={`navbar navbar-expand-lg navbar-black bg-transparent ${
                collapsed ? "" : "expanded"
              }`}
            >
              <div className="container-fluid">
                <a className="navbar-brand" href="/">
                  <h2 className="text-white logo-name">TRUFLIX</h2>
                </a>
                <button
                  className={` navbar-toggler ${collapsed ? "" : "collapsed"}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded={!collapsed}
                  aria-label="Toggle navigation"
                  onClick={toggleNavbar}
                >
                  <span className="navbar-toggler-icon top-bar"></span>
                </button>
                <div
                  className={`collapse navbar-collapse ${
                    collapsed ? "" : "show"
                  }`}
                  id="navbarNav"
                >
                  <ul className="navbar-nav m-auto">
                    <li className="nav-item  trueflix-nav">
                      <a
                        className="nav-link home-nav-link"
                        onClick={() => {
                          navigate("/movie-list");
                        }}
                      >
                        MOVIES
                      </a>
                    </li>

                    <li className="nav-item  trueflix-nav">
                      <a
                        className="nav-link home-nav-link"
                        onClick={() => {
                          navigate("/shows-list");
                        }}
                      >
                        TV
                      </a>
                    </li>

                    <li className="nav-item  trueflix-nav">
                      <a
                        className="nav-link home-nav-link"
                        onClick={() => {
                          navigate("/event-highlights");
                        }}
                      >
                        EVENTS
                      </a>
                    </li>
                  </ul>
                  <ul className="navbar-nav ml-auto d-lg-none">
                    {" "}
                    {/* Show only on mobile (extra small to large) */}
                    <li className="nav-item">
                      {/* <a className="nav-link " href="/account-creation">ACCOUNT</a> */}
                      <a
                        className="nav-link "
                        // href="/user-dashboard"
                        onClick={() => {
                          if (data == null) {
                            navigate("/sign-up");
                          } else {
                            navigate("/user-dashboard");
                          }
                        }}
                      >
                        {data == null ? "SUBSCRIBE" : "ACCOUNT"}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        onClick={() => {
                          {
                            if (data != null) {
                              localStorage.removeItem("UserInfo");
                              localStorage.removeItem("UserToken");
                              navigate("/login");
                            } else if (data == null) {
                              navigate("/login");
                            }
                          }
                        }}
                      >
                        {data == null ? "SIGN IN" : "SIGN OUT"}
                      </a>
                    </li>
                  </ul>
                  <ul className="navbar-nav d-none d-lg-flex acc-ul">
                    {" "}
                    {/* Hide on mobile (extra small to large) */}
                    <li className="nav-item">
                      <a
                        className="nav-link home-nav-link"
                        // href="/user-dashboard"
                        // onClick={notify}
                        onClick={() => {
                          if (data == null) {
                            navigate("/get-plan");
                          } else {
                            navigate("/user-dashboard");
                          }
                        }}
                      >
                        {data == null ? "SUBSCRIBE" : "ACCOUNT"}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link home-nav-link"
                        onClick={() => {
                          if (data != null) {
                            localStorage.removeItem("UserInfo");
                            localStorage.removeItem("UserToken");

                            navigate("/login");
                          } else if (data == null) {
                            navigate("/login");
                          }
                        }}
                      >
                        {data == null ? "SIGN IN" : "SIGN OUT"}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            <div className="col-lg-6 col-md-12 col-sm-12  "></div>
            {/* <div className="hero-info-container" style={{}}>
        <h4 className="hero-title">KNOW WHERE TO WATCH {"\n\n"}</h4>
        <h4 className="hero-title">THE LATEST AND GREATEST</h4>
        <p className="hero-text-1">
          Truflix is your guide to every movie and TV show. Find out
          <br /> what to watch and where.{" "}
        </p>
      </div> */}
            {/* <div className="col-lg-6 col-md-12 col-sm-12">
              {/* <div className="box breaking-bad-text"> */}

            {/* </div> */}
            {/* </div> */}
          </div>

          {/* <h4>Please create an account to continue</h4> */}
          {/* </div> */}
        </div>
      </div>
      <div className="border-rounded-section">
      
      <div className="access-btn-container">
        <div
          className="modal-button-container container-fluid"
          style={{
            height: "50%",
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <p className="btn-subtext">Truflix is your guide to the best movies, shows and<br />events. Find out what to watch and where to watch it.</p>
          <button
              onClick={()=>navigate("/get-subscription")}
            className="btn btn-light text-center suscribe-btn"
            style={{ height: "50px" }}
          >
            ACCESS
          </button>
        </div>
      </div>
      </div>
    </>
  );
};
export default HomeHeroUnPaid;

{
  /* <h5 className="watch-text">YOU GOTTA WATCH THIS</h5>
                <h1 className="breaking-bad-h1">
                  Breaking Bad{" "}
                  <svg
                    width="70"
                    height="70"
                    viewBox="0 0 106 106"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100.658 0H4.56769C2.04506 0 0 2.045 0 4.56763V100.817C0 103.34 2.04506 105.385 4.56769 105.385H100.658C103.18 105.385 105.225 103.34 105.225 100.817V4.56763C105.225 2.045 103.18 0 100.658 0Z"
                      fill="#EDEDED"
                    />
                    <path
                      d="M43.7473 69.2133L43.6974 48.764L33.6726 65.6052H30.1144L20.1296 49.1938V69.2033H12.7334V35.1211H19.26L32.0134 56.3001L44.5769 35.1211H51.0535L51.1535 69.2033H43.7573L43.7473 69.2133Z"
                      fill="black"
                    />
                    <path
                      d="M81.678 61.9176H65.8562L62.8377 69.2238H54.752L69.9441 35.1416H77.73L92.972 69.2238H84.6963L81.678 61.9176ZM79.1992 55.9308L73.7921 42.8876L68.3849 55.9308H79.1893H79.1992Z"
                      fill="black"
                    />
                  </svg>
                </h1> */
}
{
  /* <p className="hero-description-text">
                  Multiple award-winning show about a chemistry teacher’s
                  journey to becoming a ruthless drug producer after learning he
                  has a terminal disease.
                </p> */
}

{
  /* <div
            className="hero-modal"
            style={{ display: showModal ? "flex" : "none" }}
          >
            <div className="modal-logo-container">
              <h2 className="text-white">TRUFLIX</h2>
              <p style={{ color: "white" }} onClick={() => closeModal()}>
                x
              </p>
            </div>
            {/* <div className="modal-text-container">
              <p>To continue please Login or SignUp to Truflix</p>
            </div> */
}
{
}
