// import React from 'react';
// import Header from '../../components/HeaderPricing/Header';

// function AcccountcreationSignupPage() {
//   return (
//     <>
//     <Header/>
//     <div className="container mt-5 pb-5">
//       <div className="row">
//         <div className="col-lg-8">
//           <h2 className="mb-5 text-white signup-heading">LET’S GET YOU <br></br>AN ACCOUNT</h2>
//           <div className="form-group justify-content-flex">
//             <div className="col-lg-2 email">
//               <p style={{ marginTop: '30px', paddingLeft: '30px' }} className="email">Email</p>
//             </div>
//             <div className="col-lg-10">
//               <input type="email" className="form-control input-trueflix" id="email" />
//             </div>
//           </div>
//           <div className="form-group justify-content-flex">
//             <div className="col-lg-2 email">
//               <p style={{ marginTop: '20px', paddingLeft: '30px' }} className="email"> CHANGE PASSWORD </p>
//             </div>
//             <div className="col-lg-10">
//               <input type="password" className="form-control input-trueflix" id="email" />
//             </div>
//           </div>
//           <div className="form-group justify-content-flex">
//             <div className="col-lg-2 email">
//               <p style={{ marginTop: '20px', paddingLeft: '30px' }} className="email">CONFIRM PASSWORD</p>
//             </div>
//             <div className="col-lg-10">
//               <input type="password" className="form-control input-trueflix" id="email" />
//             </div>
//           </div>

//           {/* Repeat the above form-group as needed */}
//           <div className="form-group form-check mt-5">
//             <input type="checkbox" className="form-check-input" id="termsCheckbox" />
//             <label className="form-check-label text-white " htmlFor="termsCheckbox">
//               I have read and agree to the <br></br>Terms of accessing and using Truflix.com
//             </label>
//           </div>
//           <button className="account-submit sign-up mt-3">SIGN UP</button>
//         </div>
//         <div className="col-lg-4">

//         </div>
//       </div>
//     </div>
//     </>
//   );
// }

// export default AcccountcreationSignupPage;

import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/HeaderPricing/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { BASEURL } from "../../API/constants";
import Loader from "react-js-loader";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import india from "../../Assets"
import {ReactComponent as Arrow} from "../../Assets/icons/Down.svg"

function AcccountcreationSignupPage() {
  const location = useLocation();
  let referralInfo = localStorage.getItem("ReferralInfo");
  let referralData = JSON.parse(referralInfo);
  let storedFormData = localStorage.getItem("formData")
  // console.log("formDTAA",storedFormData)
  let formData = JSON.parse(storedFormData)
  let emailError = localStorage.getItem("emailError")
  let emailErrorMsg = localStorage.getItem("emailErrMsg")
  let emailErrorMessage = JSON.parse(emailErrorMsg)
  const navigate = useNavigate();
  const [email, setEmail] = useState(formData?.email || "");
  const [username, setUsername] = useState(formData?.name || "");
  const [password, setPassword] = useState(formData?.password || "");
  const [confirmPassword, setConfirmPassword] = useState(formData?.password || "");
  const [error, setError] = useState("");
  const [signupErr, setSignUpErr] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [whatsappNumber, setWhatsAppNumber] = useState(formData?.whatsapp || "");
  const [countryCode, setCountryCode] = useState(formData?.countryCode || "+1");

  const countryCodes = [
    { id: 1, code: "+93", name: "Afghanistan" },
    { id: 2, code: "+355", name: "Albania" },
    { id: 3, code: "+213", name: "Algeria" },
    { id: 4, code: "+376", name: "Andorra" },
    { id: 5, code: "+244", name: "Angola" },
    { id: 6, code: "+1", name: "Antigua and Barbuda" },
    { id: 7, code: "+54", name: "Argentina" },
    { id: 8, code: "+374", name: "Armenia" },
    { id: 9, code: "+61", name: "Australia" },
    { id: 10, code: "+43", name: "Austria" },
    { id: 11, code: "+994", name: "Azerbaijan" },
    { id: 12, code: "+1", name: "Bahamas" },
    { id: 13, code: "+973", name: "Bahrain" },
    { id: 14, code: "+880", name: "Bangladesh" },
    { id: 15, code: "+1", name: "Barbados" },
    { id: 16, code: "+375", name: "Belarus" },
    { id: 17, code: "+32", name: "Belgium" },
    { id: 18, code: "+501", name: "Belize" },
    { id: 19, code: "+229", name: "Benin" },
    { id: 20, code: "+975", name: "Bhutan" },
    { id: 21, code: "+591", name: "Bolivia" },
    { id: 22, code: "+387", name: "Bosnia and Herzegovina" },
    { id: 23, code: "+267", name: "Botswana" },
    { id: 24, code: "+55", name: "Brazil" },
    { id: 25, code: "+673", name: "Brunei" },
    { id: 26, code: "+359", name: "Bulgaria" },
    { id: 27, code: "+226", name: "Burkina Faso" },
    { id: 28, code: "+257", name: "Burundi" },
    { id: 29, code: "+855", name: "Cambodia" },
    { id: 30, code: "+237", name: "Cameroon" },
    { id: 31, code: "+1", name: "Canada" },
    { id: 32, code: "+238", name: "Cape Verde" },
    { id: 33, code: "+236", name: "Central African Republic" },
    { id: 34, code: "+235", name: "Chad" },
    { id: 35, code: "+56", name: "Chile" },
    { id: 36, code: "+86", name: "China" },
    { id: 37, code: "+57", name: "Colombia" },
    { id: 38, code: "+269", name: "Comoros" },
    { id: 39, code: "+242", name: "Congo" },
    { id: 40, code: "+682", name: "Cook Islands" },
    { id: 41, code: "+506", name: "Costa Rica" },
    { id: 42, code: "+225", name: "Côte d'Ivoire" },
    { id: 43, code: "+385", name: "Croatia" },
    { id: 44, code: "+53", name: "Cuba" },
    { id: 45, code: "+357", name: "Cyprus" },
    { id: 46, code: "+420", name: "Czech Republic" },
    { id: 47, code: "+45", name: "Denmark" },
    { id: 48, code: "+253", name: "Djibouti" },
    { id: 49, code: "+1", name: "Dominica" },
    { id: 50, code: "+1", name: "Dominican Republic" },
    { id: 51, code: "+593", name: "Ecuador" },
    { id: 52, code: "+20", name: "Egypt" },
    { id: 53, code: "+503", name: "El Salvador" },
    { id: 54, code: "+240", name: "Equatorial Guinea" },
    { id: 55, code: "+291", name: "Eritrea" },
    { id: 56, code: "+372", name: "Estonia" },
    { id: 57, code: "+251", name: "Ethiopia" },
    { id: 58, code: "+679", name: "Fiji" },
    { id: 59, code: "+358", name: "Finland" },
    { id: 60, code: "+33", name: "France" },
    { id: 61, code: "+241", name: "Gabon" },
    { id: 62, code: "+220", name: "Gambia" },
    { id: 63, code: "+995", name: "Georgia" },
    { id: 64, code: "+49", name: "Germany" },
    { id: 65, code: "+233", name: "Ghana" },
    { id: 66, code: "+30", name: "Greece" },
    { id: 67, code: "+1", name: "Grenada" },
    { id: 68, code: "+502", name: "Guatemala" },
    { id: 69, code: "+224", name: "Guinea" },
    { id: 70, code: "+245", name: "Guinea-Bissau" },
    { id: 71, code: "+592", name: "Guyana" },
    { id: 72, code: "+509", name: "Haiti" },
    { id: 73, code: "+504", name: "Honduras" },
    { id: 74, code: "+36", name: "Hungary" },
    { id: 75, code: "+354", name: "Iceland" },
    { id: 76, code: "+91", name: "India" },
    { id: 77, code: "+62", name: "Indonesia" },
    { id: 78, code: "+98", name: "Iran" },
    { id: 79, code: "+964", name: "Iraq" },
    { id: 80, code: "+353", name: "Ireland" },
    { id: 81, code: "+972", name: "Israel" },
    { id: 82, code: "+39", name: "Italy" },
    { id: 83, code: "+1", name: "Jamaica" },
    { id: 84, code: "+81", name: "Japan" },
    { id: 85, code: "+962", name: "Jordan" },
    { id: 86, code: "+7", name: "Kazakhstan" },
    { id: 87, code: "+254", name: "Kenya" },
    { id: 88, code: "+686", name: "Kiribati" },
    { id: 89, code: "+965", name: "Kuwait" },
    { id: 90, code: "+996", name: "Kyrgyzstan" },
    { id: 91, code: "+856", name: "Laos" },
    { id: 92, code: "+371", name: "Latvia" },
    { id: 93, code: "+961", name: "Lebanon" },
    { id: 94, code: "+266", name: "Lesotho" },
    { id: 95, code: "+231", name: "Liberia" },
    { id: 96, code: "+218", name: "Libya" },
    { id: 97, code: "+423", name: "Liechtenstein" },
    { id: 98, code: "+370", name: "Lithuania" },
    { id: 99, code: "+352", name: "Luxembourg" },
    { id: 100, code: "+389", name: "Macedonia" },
    { id: 101, code: "+261", name: "Madagascar" },
    { id: 102, code: "+265", name: "Malawi" },
    { id: 103, code: "+60", name: "Malaysia" },
    { id: 104, code: "+960", name: "Maldives" },
    { id: 105, code: "+223", name: "Mali" },
    { id: 106, code: "+356", name: "Malta" },
    { id: 107, code: "+692", name: "Marshall Islands" },
    { id: 108, code: "+222", name: "Mauritania" },
    { id: 109, code: "+230", name: "Mauritius" },
    { id: 110, code: "+52", name: "Mexico" },
    { id: 111, code: "+691", name: "Micronesia" },
    { id: 112, code: "+373", name: "Moldova" },
    { id: 113, code: "+377", name: "Monaco" },
    { id: 114, code: "+976", name: "Mongolia" },
    { id: 115, code: "+382", name: "Montenegro" },
    { id: 116, code: "+212", name: "Morocco" },
    { id: 117, code: "+258", name: "Mozambique" },
    { id: 118, code: "+95", name: "Myanmar" },
    { id: 119, code: "+264", name: "Namibia" },
    { id: 120, code: "+674", name: "Nauru" },
    { id: 121, code: "+977", name: "Nepal" },
    { id: 122, code: "+31", name: "Netherlands" },
    { id: 123, code: "+64", name: "New Zealand" },
    { id: 124, code: "+505", name: "Nicaragua" },
    { id: 125, code: "+227", name: "Niger" },
    { id: 126, code: "+234", name: "Nigeria" },
    { id: 127, code: "+683", name: "Niue" },
    { id: 128, code: "+850", name: "North Korea" },
    { id: 129, code: "+47", name: "Norway" },
    { id: 130, code: "+968", name: "Oman" },
    { id: 131, code: "+92", name: "Pakistan" },
    { id: 132, code: "+680", name: "Palau" },
    { id: 133, code: "+507", name: "Panama" },
    { id: 134, code: "+675", name: "Papua New Guinea" },
    { id: 135, code: "+595", name: "Paraguay" },
    { id: 136, code: "+51", name: "Peru" },
    { id: 137, code: "+63", name: "Philippines" },
    { id: 138, code: "+48", name: "Poland" },
    { id: 139, code: "+351", name: "Portugal" },
    { id: 140, code: "+974", name: "Qatar" },
    { id: 141, code: "+40", name: "Romania" },
    { id: 142, code: "+7", name: "Russia" },
    { id: 143, code: "+250", name: "Rwanda" },
    { id: 144, code: "+1", name: "Saint Kitts and Nevis" },
    { id: 145, code: "+1", name: "Saint Lucia" },
    { id: 146, code: "+1", name: "Saint Vincent and the Grenadines" },
    { id: 147, code: "+685", name: "Samoa" },
    { id: 148, code: "+378", name: "San Marino" },
    { id: 149, code: "+239", name: "Sao Tome and Principe" },
    { id: 150, code: "+966", name: "Saudi Arabia" },
    { id: 151, code: "+221", name: "Senegal" },
    { id: 152, code: "+381", name: "Serbia" },
    { id: 153, code: "+248", name: "Seychelles" },
    { id: 154, code: "+232", name: "Sierra Leone" },
    { id: 155, code: "+65", name: "Singapore" },
    { id: 156, code: "+421", name: "Slovakia" },
    { id: 157, code: "+386", name: "Slovenia" },
    { id: 158, code: "+677", name: "Solomon Islands" },
    { id: 159, code: "+252", name: "Somalia" },
    { id: 160, code: "+27", name: "South Africa" },
    { id: 161, code: "+82", name: "South Korea" },
    { id: 162, code: "+211", name: "South Sudan" },
    { id: 163, code: "+34", name: "Spain" },
    { id: 164, code: "+94", name: "Sri Lanka" },
    { id: 165, code: "+249", name: "Sudan" },
    { id: 166, code: "+597", name: "Suriname" },
    { id: 167, code: "+268", name: "Swaziland" },
    { id: 168, code: "+46", name: "Sweden" },
    { id: 169, code: "+41", name: "Switzerland" },
    { id: 170, code: "+963", name: "Syria" },
    { id: 171, code: "+886", name: "Taiwan" },
    { id: 172, code: "+992", name: "Tajikistan" },
    { id: 173, code: "+255", name: "Tanzania" },
    { id: 174, code: "+66", name: "Thailand" },
    { id: 175, code: "+228", name: "Togo" },
    { id: 176, code: "+676", name: "Tonga" },
    { id: 177, code: "+1", name: "Trinidad and Tobago" },
    { id: 178, code: "+216", name: "Tunisia" },
    { id: 179, code: "+90", name: "Turkey" },
    { id: 180, code: "+993", name: "Turkmenistan" },
    { id: 181, code: "+688", name: "Tuvalu" },
    { id: 182, code: "+256", name: "Uganda" },
    { id: 183, code: "+380", name: "Ukraine" },
    { id: 184, code: "+971", name: "United Arab Emirates" },
    { id: 185, code: "+44", name: "United Kingdom" },
    { id: 186, code: "+1", name: "United States" },
    { id: 187, code: "+598", name: "Uruguay" },
    { id: 188, code: "+998", name: "Uzbekistan" },
    { id: 189, code: "+678", name: "Vanuatu" },
    { id: 190, code: "+379", name: "Vatican City" },
    { id: 191, code: "+58", name: "Venezuela" },
    { id: 192, code: "+84", name: "Vietnam" },
    { id: 193, code: "+967", name: "Yemen" },
    { id: 194, code: "+260", name: "Zambia" },
    { id: 195, code: "+263", name: "Zimbabwe" }
];

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleSignup = async () => {
    if (!username || !email || !password || !confirmPassword) {
      setSignUpErr(true);
      setError("All fields are required.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setSignUpErr(true);
      setError("Invalid email address.");
      return;
    }

    if (password !== confirmPassword) {
      setSignUpErr(true);
      setError("Passwords do not match.");
      return;
    }

    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordErr(true);
      return;
    }

    const payload = {
      name: username,
      email: email,
      password: password,
      referral_id: referralData?.referralId,
      referral_code: referralData?.referralCode,
      country_id: referralData?.countryId,
      location_availability: true,
      referring_members: true,
      whatsapp:whatsappNumber,
      countryCode:countryCode
    };
    localStorage.setItem("formData",JSON.stringify(payload))
    localStorage.removeItem("emailError")
    localStorage.removeItem("emailErrMsg")
    navigate("/confirm-whatsapp", {
      state: {
        data: payload,
      },
    });

    // const options = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    //   body: JSON.stringify(payload),
    // };

    // try {
    //   setLoading(true);
    //   const response = await fetch(`${BASEURL}/users`, options);
    //   let data = await response.json();
    //   if (!data.status) {
    //     setSignUpErr(true);
    //     setError(data.data.email[0]);
    //     setLoading(false);
    //   } else {
    //     setPasswordErr(false);
    //     setLoading(false);
    //     navigate("/otp-verification", {
    //       state: { email: data.data.email, data: data.data },
    //     });
    //   }
    // } catch (error) {
    //   console.log("Catched Error: ", error);
    // }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\s]*$/; // Regular expression to allow only letters and spaces
    if (regex.test(value)) {
      setUsername(value);
    }
  };
  const [selected, setSelected] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const handleSelect = (code) => {
    setCountryCode(code);
    console.log("code box");
    setSelected(code);
    setIsOpen(false); // Close dropdown after selecting
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle dropdown open/close
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Header />
      <div className="container mt-5 pb-5">
        <div className="row">
          <div className="col-lg-8">
            <h2 className="mb-5 text-white signup-heading">
              LET’S GET YOU <br />
              AN ACCOUNT
            </h2>
            <div className="form-group justify-content-flex">
              <div className="col-lg-2 name">
                <p
                  style={{ paddingLeft: "30px", margin: "0" }}
                  className="name"
                >
                  Name
                </p>
              </div>
              <div className="col-lg-10">
                <TextField
                  type="text"
                  onChange={handleChange}
                  variant="outlined"
                  className="form-control input-trueflix"
                  id="name"
                  value={username}
                  // onChange={(e) => setUsername(e.target.value)}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    style: {
                      border: "none",
                      outline: "none",
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="form-group justify-content-flex">
              <div className="col-lg-2 email">
                <p
                  style={{ paddingLeft: "30px", margin: "0" }}
                  className="email"
                >
                  Email
                </p>
              </div>
              <div className="col-lg-10">
                <TextField
                  type="email"
                  variant="outlined"
                  className="form-control input-trueflix"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    style: {
                      border: "none",
                      outline: "none",
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="form-group justify-content-flex">
              <div
                className="col-lg-2 email"
                style={
                  {
                    // backgroundColor:"blue"
                  }
                }
              >
                <div className="col-md-6 col-sm-12 mb-3 mx-2 flex">
                  {/* <select
                    className="form-control mobile-field"
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "white",
                      marginTop: "20px",
                      marginLeft: "14px",
                    }}
                  >
                    {countryCodes.map((i, index) => (
                      <option
                        key={index}
                        style={{ color: "white" }}
                        value={i.code}
                      >
                        <img style={{
                          width:"50px",
                          height:"50px"
                        }} src="https://media.istockphoto.com/id/1471401435/vector/round-icon-of-indian-flag.jpg?s=1024x1024&w=is&k=20&c=wYt8ezq-hWf7P-MSFJ2GJCZ98T-F3n8ao1Y4eN0yATQ=" />
                        {/* <h4>{i.code}</h4> */}
                  {/* </option>
                    ))}
                  </select> */}
                  <div
                    style={{
                      position: "relative",
                      margin: "10px 14px",
                      display: "flex",
                      // backgroundColor: "blue",
                      width: "100px",
                      justifyContent: "center",
                      top: "6px",
                    }}
                  >
                    <div
                      style={{
                        width: "120px",
                        height: "50px",
                        // backgroundColor: "red",
                        // marginTop:"10px"
                        marginLeft: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        cursor: "pointer",
                      }}
                    >
                      <p style={{ marginTop: "22px",marginLeft:"10px" }} className="wanumber">
                      Whatsapp Number
                        {/* {selected ? selected : "Mobile Number"} */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-10"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  
                }}
              >
                <div
                  className="custom-select"
                  ref={dropdownRef}
                  onClick={() => toggleDropdown()}
                  style={{
                    width: "140px",
                    height: "100%",
                    // backgroundColor: "red",
                    zIndex: "2",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "5px",
                    position: "relative",
                    
                  }}
                >
                  <p style={{ margin: "0", cursor: "pointer", color: "white" }}>
                    {countryCode}
                  </p>
                  <Arrow style={{marginLeft:"10px",cursor:"pointer"}}/>
                  {isOpen && (
                    <div
                      // className="dropdown-menu"
                      style={{
                        position: "absolute",
                        top: "100%", // Aligns dropdown directly below the red div
                        left: 0,
                        backgroundColor: "#444",
                        border: "1px solid #ccc",
                        zIndex: 1000,
                        width: "160px",
                        overflowY: "scroll",
                        height: "300px",
                        overflowX: "hidden",
                      }}
                    >
                      {countryCodes.map((i, index) => (
                        <div
                          key={index}
                          onClick={() => handleSelect(i.code)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "8px",
                            cursor: "pointer",
                            color: "white",
                            justifyContent: "flex-start",
                            gap: "10px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            {i.code}
                          </span>

                          <span
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            {i.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div style={{
                    width:"9px",
                    height:"64px",
                    backgroundColor:"#1a1a1a",
                    // marginLeft:"1px",
                    // marginBottom:"5px"
                  }}></div>
                <TextField
                  type="Mobile Number"
                  variant="outlined"
                  className="form-control input-trueflix whatsapp-input"
                  id="email"
                  value={whatsappNumber}
                  onChange={(e) => setWhatsAppNumber(e.target.value)}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    style: {
                      border: "none",
                      outline: "none",
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <p
              style={{
                color: "white",
                paddingTop: "5px",
                marginBottom: "0px",
                fontSize: "14px",
              }}
            >
              All support is provided on Whatsapp. Please enter the phone number
              for whatsapp.
            </p>
            <div
              className="form-group justify-content-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-lg-2 password">
                <p
                  style={{ paddingLeft: "30px", margin: "0" }}
                  className="password"
                >
                  CHOOSE PASSWORD
                </p>
              </div>
              <div className="col-lg-10">
                <TextField
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  className="form-control input-trueflix"
                  id="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordErr(false);
                  }}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          // sx={{ color: "white" }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      border: "none",
                      outline: "none",
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="form-group justify-content-flex">
              <div className="col-lg-2 password">
                <p
                  style={{ paddingLeft: "30px", margin: "0" }}
                  className="password"
                >
                  CONFIRM PASSWORD
                </p>
              </div>
              <div className="col-lg-10">
                <TextField
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  className="form-control input-trueflix"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setPasswordErr(false);
                  }}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          // sx={{ color: "white" }}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      border: "none",
                      outline: "none",
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="form-group form-check mt-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="termsCheckbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label
                className="form-check-label text-white"
                htmlFor="termsCheckbox"
              >
                I have read and agree to the <br />
                Terms of accessing and using Truflix.com
              </label>
            </div>
            
            {signupErr && <p className="text-danger mb-3">{error && error}</p>}
            {emailError && <p className="text-danger mb-3">{emailErrorMessage && emailErrorMessage}</p>}
            {passwordErr && (
              <div style={{ marginTop: "10px", width: "50%" }}>
                <p style={{ color: "white", paddingTop: "10px" }}>
                  Password must have at least
                </p>
                <ul
                  style={{
                    width: "100%",
                    height: "20%",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <li className="text-danger" style={{ color: "red" }}>
                    1 uppercase letter
                  </li>
                  <li className="text-danger" style={{ color: "red" }}>
                    1 number
                  </li>
                  <li className="text-danger" style={{ color: "red" }}>
                    1 special character
                  </li>
                  <li className="text-danger" style={{ color: "red" }}>
                    8 characters long
                  </li>
                </ul>
              </div>
            )}
            <div className="form-group mt-3">
              {loading ? (
                <Loader
                  type="spinner-default"
                  bgColor={"white"}
                  color={"black"}
                  title={""}
                  size={50}
                />
              ) : (
                isChecked && (
                  <button
                    className="account-submit sign-up mt-3"
                    onClick={handleSignup}
                    style={{ margin: "0" }}
                  >
                    SIGN UP
                  </button>
                )
              )}
              <p
                onClick={() => navigate("/login")}
                style={{
                  color: "white",
                  padding: "15px",
                  paddingLeft: "10px",
                  cursor: "pointer",
                }}
              >
                Already have an account?
              </p>
            </div>
          </div>
          <div className="col-lg-4"></div>
        </div>
      </div>
    </>
  );
}

export default AcccountcreationSignupPage;
