// import React, { useEffect,useState } from "react";

// import "./ReferredUsers.css";
// import Header from "../../components/HeaderPricing/Header";
// import { useLocation } from "react-router-dom";
// import { BASEURL } from "../../API/constants";

// const ReferredUsers = () => {
//   let x = localStorage.getItem("UserInfo");
//   let token = localStorage.getItem("UserToken");
//   // console.log("UserToken",token)
//   let accessToken = JSON.parse(token);
//   let userData = JSON.parse(x);
//   const [userInfo, setUserInfo] = useState();
//   const [referredLength, setReferredlength] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [referredUsers, setReferredUsers] = useState();
//   const initialDate = new Date(userInfo?.subscription?.starts_at);
//   let monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   const threeMonthsLater = new Date(
//     initialDate.getFullYear(),
//     initialDate.getMonth() + 3,
//     initialDate.getDate(),
//     initialDate.getHours(),
//     initialDate.getMinutes(),
//     initialDate.getSeconds()
//   );
//   const month = threeMonthsLater.getMonth() + 1;
//   const date = threeMonthsLater.getDate();
//   const year = threeMonthsLater.getFullYear();

//   const monthIndex = threeMonthsLater.getMonth();
//   const monthName = monthNames[monthIndex];

//   // date function
//   function formatDate(dateString) {
//     // Convert string to Date object
//     let dateObj = new Date(dateString);

//     // Array of month names

//     // Get month, day, and year
//     let month = monthNames[dateObj.getMonth()];
//     let day = dateObj.getDate();
//     let year = dateObj.getFullYear();

//     // Format the date
//     let formattedDate = month + " " + day + ", " + year;

//     return formattedDate;
//   }

//   const getUserDetails = async () => {
//     // console.log("accessToken",accessToken)
//     try {
//       setLoading(true);
//       const response = await fetch(`${BASEURL}/user/details`, {
//         method: "GET",
//         headers: {
//           Authorization: "Bearer " + accessToken,
//         },
//       });
//       let data = await response.json();
//       console.log("user Data", data.data);
//       setReferredlength(data.data?.referred_users?.length);
//       if (data.data?.referred_users && data.data?.referred_users) {
//         setReferredUsers(data.data?.referred_users);
//       }
//       setUserInfo(data.data);
//       setLoading(false);
//     } catch (err) {
//       console.log("Error getting user details", err);
//       setLoading(false);
//     }
//   };
//   useEffect(() => {
//     getUserDetails();
//   }, []);

//   const showPendingReferalls = (arg) => {
//     let referrals = "";
//     if (arg == 0 || arg == undefined) {
//       return "three";
//     } else if (arg == 1) {
//       return "two";
//     } else if (arg == 2) {
//       return "one";
//     }
//   };

//   const UserCard = ({ userName, access_code, count, date }) => (
//     <div className="col-lg-6 col-md-6">
//       <div className="user__card">
//         <div className="serial__number">
//           <h1>{count + 1}</h1>
//         </div>
//         <div className="user__info">
//           <div className="container mt-2">
//             <span>User Name</span>
//             <h6>{userName}</h6>
//           </div>
//           <div className="container mt-3">
//             <span>Date Registered</span>
//             <h6 style={{ textTransform: "uppercase" }}>{formatDate(date)}</h6>
//           </div>
//           <div className="container mt-3">
//             <span>Code</span>
//             <h6>{access_code}</h6>
//           </div>
//         </div>
//       </div>
//     </div>
//   );

//   const color = ReferredUsers.referredUsers?.length < 3 ? "red" : "green";
//   return (
//     <div>
//       <Header />
//       <div className="container-fluid">
//         <div className="title__container">
//           <div className="text__container">
//             <h2>REFERRED USERS</h2>
//             <h2>CODE: {userData?.access_code}</h2>
//           </div>
//           <div className="count__container">
//             <div className="count__container__inner">
//               <h2 >
//                 {referredLength}/3
//               </h2>
//               <span >
//               please make sure to refer{" "}
//                         {showPendingReferalls(referredLength)} more
//               </span>
//               <span>
//               user by {monthName} {date}, {year},
//               </span>
//             </div>
//           </div>
//         </div>
//         {/* Users list container */}
//         <div className="container mt-4">
//           <div className="row">
//           {referredLength === 0 ? (
//               <p style={{color:"white"}}>No referred users yet. Please refer some people to gain access.</p>
//             ) : (
//               referredUsers?.map((user, index) => (
//                 <UserCard
//                   key={user.id}
//                   count={index}
//                   userName={user?.name}
//                   access_code={user?.access_code}
//                   date={user?.email_verified_at}
//                 />
//               ))
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReferredUsers;

import React, { useEffect, useState } from "react";
import "./ReferredUsers.css";
import Header from "../../components/HeaderPricing/Header";
import { BASEURL } from "../../API/constants";

const ReferredUsers = () => {
  let x = localStorage.getItem("UserInfo");
  let token = localStorage.getItem("UserToken");
  let accessToken = JSON.parse(token);
  let userData = JSON.parse(x);

  const [userInfo, setUserInfo] = useState();
  const [referredLength, setReferredLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [referredUsers, setReferredUsers] = useState([]);

  const initialDate = new Date(userInfo?.subscription?.starts_at);
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const threeMonthsLater = new Date(
    initialDate.getFullYear(),
    initialDate.getMonth() + 3,
    initialDate.getDate(),
    initialDate.getHours(),
    initialDate.getMinutes(),
    initialDate.getSeconds()
  );
  const monthName = monthNames[threeMonthsLater.getMonth()];
  const date = threeMonthsLater.getDate();
  const year = threeMonthsLater.getFullYear();

  // Format date function
  function formatDate(dateString) {
    let dateObj = new Date(dateString);
    let month = monthNames[dateObj.getMonth()];
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  const getUserDetails = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BASEURL}/user/details`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });
      let data = await response.json();
      console.log("user Data", data.data);
      const referredUsers = data.data?.referred_users || [];
      setReferredLength(referredUsers.length);
      setReferredUsers(referredUsers);
      setUserInfo(data.data);
      setLoading(false);
    } catch (err) {
      console.log("Error getting user details", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const showPendingReferrals = (arg) => {
    if (arg === 0 || arg === undefined) {
      return "three";
    } else if (arg === 1) {
      return "two";
    } else if (arg === 2) {
      return "one";
    }
  };

  const UserCard = ({ userName, access_code, count, date }) => (
    <div className="col-lg-6 col-md-6">
      <div className="user__card">
        <div className="serial__number">
          <h1>{count + 1}</h1>
        </div>
        <div className="user__info">
          <div className="container mt-2">
            <span>User Name</span>
            <h6>{userName}</h6>
          </div>
          <div className="container mt-3">
            <span>Date Registered</span>
            <h6 style={{ textTransform: "uppercase" }}>{formatDate(date)}</h6>
          </div>
          <div className="container mt-3">
            <span>Code</span>
            <h6>{access_code}</h6>
          </div>
        </div>
      </div>
    </div>
  );

  const color = referredLength < 3 ? "red" : "green";

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="container title__container">
          <div className="text__container">
            <h2 >REFERRED USERS</h2>
            <h2 >CODE: {userData?.access_code}</h2>
          </div>
          <div className="count__container">
            <div className="count__container__inner">
              <h2 style={{ color: color }}>{Math.min(referredLength, 3)}/3</h2>

              {referredLength >= 3 ? (
                <>
                  <span style={{ color: color }}>
                    You have successfully referred 3 users
                  </span>
                </>
              ) : (
                <>
                  <span style={{ color: color }}>
                    please make sure to refer{" "}
                    {showPendingReferrals(referredLength)} more
                  </span>
                  <span style={{ color: color }}>
                    user by {monthName} {date}, {year}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        {/* Users list container */}
        <div className="container mt-4">
          <div className="row">
            {referredLength === 0 ? (
              <div className="col-lg-6 col-md-6" style={{marginLeft:"32px"}}>
                <p style={{ color: "white"}}>
                Share Truflix with three friends to keep enjoying our services! Simply send them the provided code so they can join the fun!
              </p>
              </div>
            ) : (
              referredUsers.slice(0, 3).map((user, index) => (
                <UserCard
                  key={user.id}
                  count={index}
                  userName={user?.name}
                  access_code={user?.access_code}
                  date={user?.email_verified_at}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferredUsers;
