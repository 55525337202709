import * as React from "react";

import "./AccountCreationProcessPage.css";
// import Header from "../../components/Header/Header";
import Header from "../../components/HeaderPricing/Header";
import CustomHeading from "../../components/CustomHeading/CustomHeading";
import CustomSubheading from "../../components/CustomSubHeading/CustomSubHeading";
import OtpForm from "../../components/OtpForm/OtpForm";
import { useLocation, useNavigate } from "react-router-dom";
import { BASEURL } from "../../API/constants";
export default function AccountCreationProcessPage(props) {
  const [otp,setOtp] = React.useState()
  const [loading,setLoading] = React.useState(false)
  const [otpErr,setOtpErr] = React.useState(false)
  const [errorMsg,setErrMsg] = React.useState("")
  const location = useLocation()
  console.log("OTP Location",location)
  let email = location.state.email
  let accessToken = location.state.data.accessToken
  console.log("accessToken",accessToken)
  console.log(email)
  let OTP = location.state.data.otp
  // console.log("Email",email)
  // console.log("accessToken",accessToken)
  const navigate = useNavigate();
  console.log("otp ==>>",OTP)
  const headingText = "ENTER CODE THAT WAS SENT TO YOUR EMAIL.";
  const subheadingText = `We sent you a four digit code to your email ${email}. It may take upto 60 seconds to arrive.`;
  let otpString = otp?.join('')
  const payload = {
    email: email,
    otp: otpString,
  };
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:"Bearer " + accessToken
    },
    body:JSON.stringify(payload),
  };

  // handle otp submit
  const handleOtpSubmission = async  ()=>{
    console.log("options",options)
    try{
      if(otpString == "" || otpString == undefined){
        setOtpErr(true)
        setErrMsg("Invalid OTP")
        return
      }
      // console.log(otpString)
      setLoading(true)
      const response = await fetch(`${BASEURL}/users/verify-otp`,options)
      let data = await response.json()
      console.log("OTP Verification Response",data)
      setLoading(false)
      if(!data.status){
        if(data.message === "Account is already verified."){
          setOtpErr(true)
          // alert("Account is already verified.")
          // navigate("/login")
          setErrMsg("Account is already verified.")
          setLoading(false)
          
        }else if(data.message === "Invalid OTP Code"){
          setLoading(false)
          setOtpErr(true)
          setErrMsg("Invalid OTP Code")
          // alert("OTP Verification Failure \n Invalid OTP")
        }
      }
      else if(data.status){
        // alert("OTP Verification Success")
        console.log(data.data.token.current_token)
       
        localStorage.removeItem("formData")
        setLoading(false)
        setOtpErr(false)
        // navigate("/login")
        navigate("/pricing-plan",{state:{email:payload.email,data:data.data,token:data.data.token.current_token}})
      }
    }catch(err){
      console.log("OTP Error: ",err)
      setLoading(false)
    }
  }

  return (
    <>
      <Header />
      <div className="access-code-1">
        <div className="access-code-2">
          <CustomHeading width={"59%"} headingText={headingText} />
          <CustomSubheading subheadingText={subheadingText} />

          <div className="otp-form">
            <OtpForm
              buttontext="CONTINUE"
              onClick={() => handleOtpSubmission()}
              otplength={["", "", "", ""]}
              storeOtp={setOtp}
              loading={loading}
            />
          </div>
          {otpErr && (
            <p style={{color:"red",paddingTop:"15px"}}>{errorMsg}</p>
          )}
        </div>
      </div>
    </>
  );
}
